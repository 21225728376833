import { observer } from 'mobx-react';
import React from 'react';
import App from '../stores/App';
import Breakout from '../stores/Breakout';

const DebugModule = observer(
  class DebugModule extends React.Component {

    render() {
      if (App.debug_enabled) {
        if (App.debug_container_visible) {
          return (
            <div className="clip clip__breakout-debug">
              <p>App loading? <b>{App.is_loading ? "Yes" : "No"}</b></p>
              <p>App did error? <b>{App.error !== "" ? "Yes" : "No"}</b></p>
              <p>App error: <b>{App.error !== "" ? App.error : "N/A"}</b></p>
              <p>Open?: <b>{Breakout.main_container_is_open ? "Yes" : "No"}</b></p>
              <p>Active Room: <b>{Breakout.active_room?.title}</b></p>
              <button onClick={App.toggle_debug_container_visible}>Hide debug <span role="img" aria-label="Mind blown">🤯</span></button>
            </div>
          )
        }
        return (
          <div className="clip clip__breakout-debug hidden">
            <button onClick={App.toggle_debug_container_visible}>Show debug <span role="img" aria-label="OMG what is going on">😱</span></button>
          </div>
        )

      }
      return null
    }

  }
);
export default DebugModule