/*eslint require-yield: "off"*/
import { flow, types } from 'mobx-state-tree';
import Breakout from './Breakout';
import Logger from './Logger';

const PreFlight = types
	.model('PreFlight', {
		did_pass_preflight: types.optional(types.boolean, false)
	})
	.actions(self => ({

		init: flow(function*() {
			Logger.log("PreFlight:init")
			return self.did_pass_preflight = yield Breakout.init()
		}),

	}))
	.create();

export default PreFlight;
