import { useMeetingManager, DeviceLabels, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';
import React, { useState, useEffect } from 'react';
import Breakout from '../stores/Breakout';
import Logger from './../stores/Logger';
import ActiveRoomComponent from '../components/room/active_room';
import WaitingRoomComponent from '../components/room/waiting_room';
// import { autorun } from 'mobx'

const RoomModule = () => {
  const [ is_meeting_joined, set_is_meeting_joined ] = useState(false)
  const [ is_pre_meeting_joined, set_is_pre_meeting_joined ] = useState(false)
  const meeting_manager = useMeetingManager()
  const meeting_status = useMeetingStatus()

  useEffect(() => {
    meeting_manager.getAttendee = async (chimeAttendeeId, externalUserId = null) => {
      const participant = await Breakout.active_room.get_participant(chimeAttendeeId)
      return {
        name: participant.name,
        id: externalUserId
      }
    }
    Logger.log('>>>>>>>>> MEETING STATUS', meeting_status)
    if (meeting_status === 1) {
      set_is_meeting_joined(true)
      Breakout.active_room.set_joining(false)
    }
    else if (meeting_status === 2 || meeting_status === 3) {
      alert("The meeting has ended.")
      meeting_manager.leave()
      Breakout.leave(false)
    }
  }, [meeting_status, meeting_manager])
  
  const _join_meeting = async () => {
    const join_data = {
      meetingInfo: Breakout.active_room,
      attendeeInfo: Breakout.active_room.current_user(),
      deviceLabels: DeviceLabels.AudioAndVideo
    }
    Logger.log("_join_meeting", join_data)
    await meeting_manager.join(join_data)
    set_is_pre_meeting_joined(true)
    if (Breakout.active_room && !Breakout.is_leaving) {
      Breakout.active_room.join_room()
    }
  }

  if (!is_pre_meeting_joined) {
    _join_meeting()
  }

  return is_meeting_joined ? <ActiveRoomComponent /> : is_pre_meeting_joined && Breakout.active_room.current_user() != null ? <WaitingRoomComponent /> : <div className="clip__breakout-active-room"></div>
}
export default RoomModule