/*eslint require-yield: "off"*/
import { applySnapshot, flow, types } from 'mobx-state-tree';
import Breakout from '../Breakout';
import Logger from '../Logger';

const Participant = types
	.model('Participant', {
		id: types.identifier,
		is_present: types.optional(types.boolean, false),
		// AWS Chime specific details
		AttendeeId: types.maybeNull(types.string),
		JoinToken: types.maybeNull(types.string),
		wp_uid: types.maybeNull(types.number),
		directory_ref: types.maybeNull(types.string),
		name: types.maybeNull(types.string),
		is_muted: types.optional(types.boolean, false),
		is_kicked: types.optional(types.boolean, false),
	})
	.actions(self => ({

		update: flow(function*(data) {
			Logger.log("Participant:update", self.id, data)
			if (self.name != null && self.name !== "") {
				data.name = self.name
			}
			applySnapshot(self, data)
			if (self.name === null || self.name === "") {
				self.update_name()
			}
		}),

		update_name: flow(function* () {
			Logger.log("Participant:update_name", self)
			if (self.name === null || self.name === "") {
				const data = yield Breakout.get_directory_info(self.directory_ref)
				Logger.log("Participant:update_name:data", self.id, data)
				if (data.first_name != null && data.last_name != null) {
					self.name = `${ data.first_name } ${ data.last_name }`
					Logger.log("Participant:update_name", self.id, self.name)
				}
			}
		}),

	}))
	.views(self => ({
		is_current_user() {
			return Breakout.user?.wp_uid === self.wp_uid
		}
	}))

export default Participant;
