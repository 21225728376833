import { observer } from 'mobx-react';
import React from 'react';
import Breakout from '../stores/Breakout';

const RoomsList = observer(
	class RoomsList extends React.Component {

		_return_rooms = () => {
			return Breakout.sorted_published_rooms().map(room => {
				return (
					<div className="clip__breakout-rooms--list--room" key={room.id}>
						<label>Title:</label>
						<h4>{room.title}</h4>
						<label>Description:</label>
						<p>{room.desc}</p>
						<label>Participants:</label>
						<span>{room.participants_text()}</span>
						{
							room.start_date && room.end_date ?
							<>
								<label>Start time:</label>
								<span>{room.start_time()}</span>
								<label>Duration:</label>
								<span>{room.duration()}</span>	
							</>
							: null
						}
						<p className="clip__breakout-rooms--list--room--info">You can join the call in the next screen after you're all set up.</p>
						{room.is_active &&
							<span className="room_is_active">
								Live
								<svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
								</svg>
							</span>
						}
						{
							room.is_joining ?
								<>
									<button className="animate__animated animate__pulse animate__infinite">
										Getting ready...
									</button>
								</>
								
							:
							Breakout.user.is_moderator() && !room.current_user()?.is_kicked ?
							<button onClick={room.set_active} >Join...</button>
							:
							room.current_user()?.is_kicked ?
							<button className="full" >Not available</button>
							:
							<button onClick={room.is_full() ? null : room.set_active} className={`${room.is_full() ? "full" : ""}`}>{room.is_full() ? "Full" : "Join..."}</button>	
						}
					</div>
				)
			})
		}

		render() {
			return (
				<div className={`clip__breakout-rooms ${!Breakout.main_container_is_open ? "inactive" : ""}`}>
					<div className="clip__breakout-rooms--header">
						<h3>
							<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
							</svg>
							Breakout Rooms
						</h3>
						<button title="Close" onClick={Breakout.toggle_main_container}>
							Close
							<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>
					<div className="clip__breakout-rooms--list">
						{this._return_rooms()}
					</div>
				</div>
			)
		}
	}
);
export default RoomsList