/*eslint require-yield: "off"*/
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { collection, doc, getDoc, getFirestore, onSnapshot, query } from "firebase/firestore";
import { flow, types } from 'mobx-state-tree';
import XPCApi, { ERROR, NOT_VERIFIED } from '../api/XPCApi';
import App from './App';
import Logger from './Logger';
import Room from './models/Room';
import User from './models/User';

export const app = initializeApp({
	apiKey: 'AIzaSyCnR4MAJv5odjnr2KBh3jzHGEnIgUwQd1M',
	authDomain: 'parasol-xpc.firebaseapp.com',
	databaseURL: 'https://parasol-xpc-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'parasol-xpc',
	storageBucket: 'parasol-xpc.appspot.com',
	messagingSenderId: '671138391297',
	appId: '1:671138391297:web:ce4a663fdd9c4786b3e700'
});

const auth = getAuth(app)
const database = getFirestore(app)
let main_db_document = null
let waiting_room_video_ref = null

const Breakout = types
	.model('Breakout', {
		is_loading: types.optional(types.boolean, true),
		error: types.optional(types.string, ""),
		jwt_token: types.maybeNull(types.string),
		is_disabled: types.optional(types.boolean, false),
		main_container_is_open: types.optional(types.boolean, false),
		uid: types.maybeNull(types.string),
		user: types.maybeNull(User),
		breakout_rooms_active: types.optional(types.boolean, false),
		is_loading_directory: types.optional(types.boolean, false),
		rooms: types.optional(types.array(Room), []),
		active_room: types.maybeNull(types.reference(Room)),
		waiting_room_active: types.optional(types.boolean, false),
		waiting_room_did_user_media_error: types.optional(types.boolean, false),
		is_leaving: types.optional(types.boolean, false),
	})
	.actions(self => ({

		init: flow(function*() {
			Logger.log("Breakout::init")
			const anonymous_auth = yield signInAnonymously(auth)
			Logger.log("Breakout:init:anonymous_auth", anonymous_auth)
			if (anonymous_auth && anonymous_auth?.user?.uid) {
				Logger.log("Breakout:init:anonymous_auth:uid", anonymous_auth.user.uid)
				self.uid = anonymous_auth.user.uid
				const verified = yield XPCApi.verify_token()
				if (verified && verified !== ERROR && verified !== NOT_VERIFIED) {
					self.uid = verified.docRefId
					return true
				}
			}
			return false
		}),

		hydrate: flow(function*() {
			Logger.log("Breakout:hydrate", App.nice_iss_token())
			main_db_document = doc(database, 'sites', App.nice_iss_token())
			const document_snapshot = yield getDoc(main_db_document)
			if (document_snapshot.exists()) {
				Logger.log("Breakout:hydrate:config", document_snapshot.data())
				if (document_snapshot.data().breakout_rooms_active != null) {
					self.breakout_rooms_active = document_snapshot.data().breakout_rooms_active
					onSnapshot(main_db_document, (doc) => {
						Breakout.handle_breakout_rooms_active(doc.data())
					})
					yield Breakout.hydrate_directory()
					if (self.has_basic_details_to_start_hydrate()) {
						yield Breakout.hydrate_rooms()
					}
					else {
						return false
					}
					self.is_loading = false
					return true
				}
			}
			return false
		}),

		handle_breakout_rooms_active: flow(function*(data) {
			Logger.log("Breakout:handle_breakout_rooms_active", data)
			if (data && data.breakout_rooms_active != null) {
				self.breakout_rooms_active = data.breakout_rooms_active
				if (!self.breakout_rooms_active) {
					self.main_container_is_open = false
				}
			}
			Logger.log("Breakout:handle_breakout_rooms_active:is_active", self.breakout_rooms_active)
		}),

		toggle_main_container: flow(function*() {
			Logger.log("Breakout:toggle_main_container")
			self.main_container_is_open = !self.main_container_is_open
		}),

		hydrate_directory: flow(function*() {
			Logger.log("Breakout:hydrate_directory")
			self.is_loading_directory = true
			const directory_doc = doc(main_db_document, 'directory', self.uid)
			const current_user = yield getDoc(query(directory_doc))
			Logger.log("Breakout:hydrate_directory:query:current_user", current_user?.id)
			if (current_user?.id === self.uid) {
				self.user = { id: current_user.id, ...current_user.data() }
				// TODO (optional): Load all user data from directory
				self.is_loading_directory = false
				return true
			}

			self.is_loading_directory = false
			return false
		}),

		get_directory_info: flow(function*(id) {
			Logger.log("Breakout:get_directory_info", id)
			if (id) {
				const directory_doc = doc(main_db_document, 'directory', id)
				const user = yield getDoc(query(directory_doc))
				Logger.log("Breakout:get_directory_info:query", user?.id)
				//return null
				if (user != null) {
					return {...user.data()}
				}
			}
			return null
		}),

		hydrate_rooms: flow(function*() {
			Logger.log("Breakout:hydrate_rooms")
			self.is_loading_rooms = true
			const rounds_collection = collection(main_db_document, 'breakout_rooms')
			onSnapshot(rounds_collection, (doc) => {
				Breakout.update_rooms(doc.docChanges())
			})
			self.is_loading_rooms = false
		}),

		update_rooms: flow(function*(data) {
			Logger.log("Breakout:update_rooms", data)
			data.forEach((change) => {
				if (change.type !== "modified" && change.type !== "added") {
					return
				}
				const doc = change.doc
				Logger.log("Breakout:update_rooms:doc", doc.data())
				const existing_room = self.rooms.find(p => p.id === doc.id)
				if (existing_room) {
					existing_room.update(self.transform_room_data(doc))
				}
				else {
					self.rooms.push(self.transform_room_data(doc))
				}
			})
			return
		}),

		init_room_participants: flow(function*(room) {
			Logger.log("Breakout:init_room_participants", room.id)
			const participants_query = query(collection(main_db_document, 'breakout_rooms', room.id, 'participants'))
			onSnapshot(participants_query, (doc) => {
				room.handle_participant_updates(doc.docChanges())
			})
		}),

		set_active_room: flow(function*(room) {
			Logger.log("Breakout:set_active_room", room)
			self.active_room = room
		}),

		set_waiting_room_active: flow(function*(active = true) {
			Logger.log("Breakout:set_waiting_room_active", active)
			self.waiting_room_active = active
			// TODO: Tell the livestream to MUTE or UNMUTE
			if (self.waiting_room_active) {
				// TODO: MUTE
			}
			else {
				// TODO: UNMUTE
				Breakout.remove_user_media()
			}
		}),

		cancel_waiting_room: flow(function*() {
			Logger.log("Breakout:cancel_waiting_room")
			yield Breakout.set_waiting_room_active(false)
			yield Breakout.active_room?.cancel_join()
			Breakout.set_active_room(null)
		}),

		set_waiting_room_video_ref: flow(function*(ref) {
			Logger.log("Breakout:set_waiting_room_video_ref", ref)
			if (ref.current != null) {
				waiting_room_video_ref = ref.current
			}
		}),

		get_and_check_user_media: flow(function*() {
			Logger.log("Breakout:get_and_check_user_media")
			if (waiting_room_video_ref != null && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices.getUserMedia({ video: true })
					.then(function(stream) {
						waiting_room_video_ref.srcObject = stream;
					})
					.catch(function(error) {
						Logger.log("Breakout:get_and_check_user_media:error", error);
						Breakout.set_user_media_did_error()
					})
			}
		}),

		set_user_media_did_error: flow(function*() {
			self.waiting_room_did_user_media_error = true
		}),

		remove_user_media: flow(function*() {
			Logger.log("Breakout:remove_user_media")
			if (waiting_room_video_ref != null) {
				const stream = waiting_room_video_ref.srcObject
				if (stream) {
					const tracks = stream.getTracks()
					if (tracks) {
						tracks.forEach(function(track) {
							track.stop()
						})
					}
				}
				waiting_room_video_ref.srcObject = null
			}
			self.waiting_room_did_user_media_error = false
		}),

		leave: flow(function*(show_alert = false) {
			Logger.log("Breakout:leave")
			self.is_leaving = true
			try {
				yield Breakout.active_room.leave()
			}
			catch(error) {
				Logger.log("Breakout:leave:error", error)
			}
			yield Breakout.remove_user_media()
			self.active_room = null
			self.is_leaving = false

			if (show_alert) {
				Breakout.show_alert("Your meeting session has expired.")
			}

		}),

		show_alert: flow(function*(message) {
			Logger.log("Breakout:show_alert", message)
			window.alert(message)
		})

	}))
	.views(self => ({
		has_basic_details_to_start_hydrate() {
			return self.user
		},
		can_show_button() {
			return self.breakout_rooms_active && self.rooms?.length > 0 && this.published_rooms()?.length > 0
		},
		published_rooms() {
			return self.rooms?.filter(room => room.is_published)
		},
		sorted_published_rooms() {
			let rooms = this.published_rooms()
			return rooms?.sort((a, b) => {
				return a.start_date - b.start_date
			})
		},
		joined_room() {
			return self.active_room?.is_joined && self.active_room.show_meeting_screen
		},
		transform_room_data(doc) {
      let data = doc.data()
      data.id = doc.id
      if (data.start_date) {
        data.start_date = data.start_date.toDate()
      }
      if (data.end_date) {
        data.end_date = data.end_date.toDate()
      }
      return data
    },
	}))
	.create();

export default Breakout;
