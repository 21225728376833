import 'animate.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './modules/App';
import DebugModule from './modules/Debug';
import './utils/cookies';

const element = document.querySelector('[data-react-clip="breakout-module"]')

if (element) {
  ReactDOM.render(
    <React.StrictMode>
      <App element={element} />
      <DebugModule />
    </React.StrictMode>,
    element
  );
}
