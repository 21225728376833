/*eslint require-yield: "off"*/
import { types } from 'mobx-state-tree';

const User = types
	.model('User', {
		id: types.identifier,
		identity_verified: types.optional(types.boolean, false),
		first_name: types.maybeNull(types.string),
		last_name: types.maybeNull(types.string),
		profile_photo_url: types.maybeNull(types.string),
		role: types.maybeNull(types.string),
		wp_uid: types.maybeNull(types.number)
	})
	.views(self => ({
		is_moderator() {
			return self.role === "moderator"
		}
	}))

export default User;
