/*eslint require-yield: "off"*/
import dayjs from 'dayjs'
import { applySnapshot, destroy, flow, types } from 'mobx-state-tree';
import XPCApi, { JOIN_ERROR, UPDATE_ERROR } from '../../api/XPCApi';
import Breakout from '../Breakout';
import Logger from '../Logger';
import Participant from './Participant';

let JOIN_TIMEOUT = null;
let PRESENCE_INTERVAL = null;

const Room = types
	.model('Room', {
		id: types.identifier,
		title: types.maybeNull(types.string),
		desc: types.maybeNull(types.string),
		is_active: types.optional(types.boolean, false),
		is_published: types.optional(types.boolean, false),
		max_count: types.optional(types.number, 240),
		participants: types.optional(types.array(Participant), []),
		is_joining: types.optional(types.boolean, false),
		is_about_to_join: types.optional(types.boolean, false),
		is_joined: types.optional(types.boolean, false),
		is_ready_to_join: types.optional(types.boolean, false),
		is_meeting_active: types.optional(types.boolean, false),
		did_error: types.optional(types.boolean, false),
		show_meeting_screen: types.optional(types.boolean, false),
		// AWS Chime specific details
		MeetingId: types.maybeNull(types.string),
		MediaRegion: types.maybeNull(types.string),
		MediaPlacement: types.maybeNull(types.model("MediaPlacement", {
			AudioFallbackUrl: types.maybeNull(types.string),
			AudioHostUrl: types.maybeNull(types.string),
			EventIngestionUrl: types.maybeNull(types.string),
			ScreenDataUrl: types.maybeNull(types.string),
			ScreenSharingUrl: types.maybeNull(types.string),
			ScreenViewingUrl: types.maybeNull(types.string),
			SignalingUrl: types.maybeNull(types.string),
			TurnControlUrl: types.maybeNull(types.string),
		})),
		start_date: types.maybeNull(types.Date),
		end_date: types.maybeNull(types.Date),
		is_muted: types.optional(types.boolean, false),
	})
	.actions(self => ({

		afterCreate: flow(function*() {
			Logger.log("Room:afterCreate", self.id)
			Breakout.init_room_participants(self)
		}),

		update: flow(function*(data) {
			Logger.log("room:update", self.id, data)
			if (data.participants == null) {
				data.participants = self.participants
			}
			data.is_joining = self.is_joining
			data.is_about_to_join = self.is_about_to_join
			data.is_meeting_active = self.is_meeting_active
			data.show_meeting_screen = self.show_meeting_screen
			data.is_joined = self.is_joined

			applySnapshot(self, data)
		}),

		set_active: flow(function*() {
			Logger.log("Room:set_active", self.id)
			yield self.join_room()
			yield Breakout.set_active_room(self)
			yield Breakout.set_waiting_room_active(true)
		}),

		handle_participant_updates: flow(function*(data) {
			Logger.log("Room:handle_participant_updates", data)
			data.forEach((change) => {
				if (change.type !== "modified" && change.type !== "added" && change.type !== "removed") {
					return
				}
				const doc = change.doc
				Logger.log("Breakout:handle_participant_updates:doc", doc.data())
				const existing_participant = self.participants.find(p => p.id === doc.id)
				if (change.type === "removed") {
					if (existing_participant) {
						destroy(existing_participant)
					}
				}
				else {
					if (existing_participant) {
						existing_participant.update(self.transform_participant_data(doc))
						if (existing_participant === self.current_user() && !existing_participant.is_present && self.id === Breakout.active_room?.id && !self.is_joining && !self.is_about_to_join) {
							Breakout.leave(true)
						}
					}
					else {
						self.participants.push(self.transform_participant_data(doc))
					}
				}
			})
		}),

		join_room: flow(function*() {
			Logger.log("Room:join_room", self.id, Breakout.user.id)
			self.is_joining = true
			self.is_about_to_join = true
			self.did_error = false
			const join_result = yield XPCApi.update_participants(self.id, { [Breakout.user?.id]: {is_present: true} })
			Logger.log("Room:join_room:join", join_result)
			// We now go ahead and add/update the correct user
			if (join_result !== null && join_result !== JOIN_ERROR && join_result !== UPDATE_ERROR) {
				self.is_about_to_join = true
				JOIN_TIMEOUT = setTimeout(() => {
					self.handle_join()
				}, 800)
			}
			else {
				self.is_joining = false
				self.is_about_to_join = false
				self.did_error = true
			}
		}),

		show_meeting_room: flow(function*() {
			Logger.log("Room:show_meeting_screen")
			if(self.current_user()){
				const join_result = yield XPCApi.update_participants(self.id, { [ Breakout.user?.id ]: { is_present: true } })
				if(join_result !== UPDATE_ERROR){
					self.show_meeting_screen = true
				}
			}
		}),

		handle_join: flow(function*() {
			Logger.log("Room:handle_join", self.id, Breakout.user.id, self.current_user())
			clearTimeout(JOIN_TIMEOUT)
			if (self.current_user()?.AttendeeId != null && self.current_user()?.JoinToken != null && self.is_about_to_join) {
				self.is_joined = true
				self.is_about_to_join = false
				self.is_joining = false
				self.is_ready_to_join = true
				Logger.log("Room:handle_join:is_ready", self.is_ready_to_join)
				// Now we want to set off a time to check the user is_present
				clearInterval(PRESENCE_INTERVAL)
				XPCApi.update_participants(self.id, { [ Breakout.user?.id ]: { is_present: true, is_muted: false } })
				PRESENCE_INTERVAL = setInterval(() => {
					self.presence_ping()
				}, 5000)
			}
			else {
				JOIN_TIMEOUT = setTimeout(() => {
					self.handle_join()
				}, 800)
			}
		}),

		cancel_join: flow(function*() {
			Logger.log("Room:cancel_join", self.id)
			clearTimeout(JOIN_TIMEOUT)
			clearInterval(PRESENCE_INTERVAL)
			self.is_joining = false
			self.is_about_to_join = false
			self.is_joined = false
			self.show_meeting_screen = false
			XPCApi.update_participants(self.id, { [ Breakout.user?.id ]: { is_present: false } })
		}),

		leave: flow(function*() {
			Logger.log("Room:leave", self.id)
			self.cancel_join()
		}),

		set_joining: flow(function*(joining) {
			Logger.log("Room:is_joining", joining)
			self.is_joining = joining
		}),

		presence_ping: flow(function*() {
			Logger.log("Room:presence_ping", self.id)
			XPCApi.update_participants(self.id, { [ Breakout.user?.id ]: { is_present: true, is_muted: false } })
		}),

		get_participant: flow(function*(id) {
			Logger.log("Room:get_participant", id)
			return self.participants.find(p => p.AttendeeId === id)
		}),

		handle_participant_action: flow(function*(action = null, participant_id = null) {
			Logger.log("Room:handle_participant_action", action, participant_id)
			if (action == null || participant_id == null) {
				return
			}
			if (action === "kick") {
				XPCApi.update_participants(self.id, { [ participant_id ]: { is_kicked: true } })
			}
			else if (action === "mute") {
				XPCApi.update_participants(self.id, { [ participant_id ]: { is_muted: true } })
			}
		}),

	}))
	.views(self => ({
		is_full() {
			return this.active_participants_count() >= self.max_count
		},
		participants_text() {
			if (!Breakout.user?.is_moderator() && !self.is_active) {
				return `Max ${self.max_count} participants`
			}
			return `${this.active_participants_count()} out of ${self.max_count} participants`
		},
		active_participants_count() {
			return self.participants.filter(p => p.is_present).length
		},
		current_user() {
			return self.participants.find(p => p.wp_uid === Breakout.user.wp_uid)
		},
		start_time() {
			return dayjs(self.start_date).format("h:mm a")
		},
		duration() {
			return `${dayjs(self.end_date).diff(dayjs(self.start_date), 'minute')} minutes`
		},
		transform_participant_data(doc) {
      let data = doc.data()
      data.id = doc.id
			if (data.directory_ref) {
				Logger.log("Room:transform_participant_data", data.directory_ref.path.split("/")[3])
        data.directory_ref = data.directory_ref.path.split("/")[3]
      }
      return data
		}
	}))
export default Room;
