/*eslint require-yield: "off"*/
import { flow, types } from "mobx-state-tree";
import Breakout from "./Breakout";
import Logger from "./Logger";
import PreFlight from "./PreFlight";

let CONTAINER_ELEMENT = null;

const App = types
  .model("App", {
    is_loading: types.optional(types.boolean, true),
    error: types.optional(types.string, ""),
    debug_enabled: types.optional(types.boolean, false),
    debug_container_visible: types.optional(types.boolean, true),
    jwt_token: types.maybeNull(types.string),
    is_disabled: types.optional(types.boolean, false),
    container_is_open: types.optional(types.boolean, true),
    theme: types.optional(types.string, "dark"),
  })
  .actions((self) => ({
    hydrate: flow(function* (element) {
      CONTAINER_ELEMENT = element;
      if (element?.getAttribute("data-enable-debug") !== null) {
        self.debug_enabled = true;
        const storage_item = localStorage.getItem("breakout:show_app_debug");
        if (storage_item) {
          self.debug_container_visible = JSON.parse(storage_item);
        }
      }
      Logger.log("App:hydrate", element);
      const stop_loading = yield App.load_for_enabled_users_only();
      if (stop_loading) {
        return;
      }
      self.is_loading = true;
      self.error = "";

      yield App.load_and_set_attributes();
      self.cleanup_dom();

      if (self.jwt_token) {
        const is_valid_preflight = yield PreFlight.init();
        if (is_valid_preflight && App.nice_iss_token() != null) {
          const hydrate_status = yield Breakout.hydrate();
          if (!self.can_show_clip() && !hydrate_status) {
            self.is_disabled = true;
            App.do_nothing(
              "PreFlight was OK, but couldn't load Breakout module!",
            );
          }
        } else {
          self.is_disabled = true;
          App.do_nothing("PreFlight was bad. Couldn't load Breakout module!");
        }
      }

      setTimeout(() => {
        App.set_is_loading(false);
      }, 500);

      Logger.log("App:hydrate:DONE_AND_A_OK");
    }),

    load_for_enabled_users_only: flow(function* () {
      Logger.log("App:load_for_enabled_users_only");
      if (CONTAINER_ELEMENT?.getAttribute("data-active-user-only") !== null) {
        const is_active = document.querySelector(".user-active");
        Logger.log("App:hydrate:user_is_active_class", is_active);
        if (!is_active) {
          // Check if we are inactive first
          const is_inactive = document.querySelector(".user-inactive");
          if (is_inactive) {
            App.set_error_message("No user is active yet... Waiting");
            setTimeout(() => {
              App.hydrate(CONTAINER_ELEMENT);
            }, 5000);
          } else {
            App.set_error_message("No user is active");
            setTimeout(() => {
              App.hydrate(CONTAINER_ELEMENT);
            }, 5000);
          }
          return true;
        }
      }
      return false;
    }),

    set_is_loading: flow(function* (loading) {
      Logger.log("App:set_is_loading", loading);
      self.is_loading = loading;
    }),

    load_and_set_attributes: flow(function* () {
      Logger.log("App:load_and_set_attributes", CONTAINER_ELEMENT);
      self.jwt_token =
        self.debug_enabled && process.env.NODE_ENV === "development" &&
          CONTAINER_ELEMENT?.getAttribute("data-debug-jwt-token")
          ? CONTAINER_ELEMENT?.getAttribute("data-debug-jwt-token")
          : window.getCookie("token");
      Logger.log("App:load_and_set_attributes:set", self);
      if (self.jwt_token === null) {
        App.set_error_message("No token was provided");
        return false;
      }
      const theme = CONTAINER_ELEMENT?.getAttribute("data-theme");
      if (theme && (theme === "dark" || theme === "light")) {
        self.theme = theme;
      }
      return true;
    }),

    set_error_message: flow(function* (message) {
      Logger.log("App:set_error_message", message);
      self.is_loading = true;
      self.error = message;
    }),

    do_nothing: flow(function* (message) {
      // Do nothing. Keep everything hidden and don't process anything else.
      // Let's just log it, so you can see it in debug mode.
      App.set_error_message(message);
    }),

    cleanup_dom: flow(function* () {
      Logger.log("App:cleanup_dom", CONTAINER_ELEMENT);
      if (!App.debug_enabled) {
        // Let's clean up the DOM a little bit.
        CONTAINER_ELEMENT.removeAttribute("data-active-user-only");
        CONTAINER_ELEMENT.removeAttribute("data-enable-debug");
        CONTAINER_ELEMENT.removeAttribute("data-debug-jwt-token");
      }
      CONTAINER_ELEMENT.setAttribute("data-module-did-load", "true");
    }),

    toggle_debug_container_visible: flow(function* () {
      Logger.log("App:toggle_debug_container_visible");
      self.debug_container_visible = !self.debug_container_visible;
      localStorage.setItem(
        "breakout:show_app_debug",
        self.debug_container_visible,
      );
    }),

    toggle_container_open: flow(function* () {
      Logger.log("App:toggle_container_open");
      self.container_is_open = !self.container_is_open;
      if (self.container_is_open) {
        CONTAINER_ELEMENT.classList.remove("closed");
      } else {
        CONTAINER_ELEMENT.classList.add("closed");
      }
    }),
  }))
  .views((self) => ({
    parsed_jwt_token() {
      var base64_url = self.jwt_token.split(".")[1];
      var base64 = base64_url.replace(/-/g, "+").replace(/_/g, "/");
      var json_payload = decodeURIComponent(
        atob(base64).split("").map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""),
      );
      return JSON.parse(json_payload);
    },

    can_show_clip() {
      return PreFlight.did_pass_preflight;
    },

    nice_iss_token() {
      return this.parsed_jwt_token()?.iss
        ? this.parsed_jwt_token()?.iss.replace("https://", "").replace(
          "http://",
          "",
        )
        : null;
    },
  }))
  .create();

export default App;
