import axios from 'axios';
import App from '../stores/App';
import Breakout from '../stores/Breakout';
import Logger from '../stores/Logger';

export const API_URL = "https://europe-central2-parasol-xpc.cloudfunctions.net"
export const ERROR = 1
export const NOT_VERIFIED = 2
export const JOIN_ERROR = 3
export const UPDATE_ERROR = 4

class XPCApi {

  async verify_token() {
    Logger.log('XPCApi:verify_token', App.jwt_token, Breakout.uid);
    const data = axios
      .post(`${API_URL}/verifyToken`, {
        anonymous_uid: Breakout.uid
      }, {
        headers: {
          Authorization: `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
      .then(response => {
        Logger.log('XPCApi:verify_token:data', response.data);
        if (response.data?.signature_verified === true) {
          return response.data
        }
        else {
          return NOT_VERIFIED
        }
      })
      .catch(error => {
        Logger.warn('XPCApi:verify_token:error', error);
        return ERROR
      });
    return data;
  }

  async update_participants(room_id, participants_data) {
    Logger.log('XPCApi:update_participants', App.jwt_token, room_id, participants_data);
    const data = axios
      .post(`${API_URL}/updateParticipants`, {
        breakout_room_uid: room_id,
        directory_uids: participants_data
      }, {
        headers: {
          Authorization: `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
      .then(response => {
        Logger.log('XPCApi:update_participants:data', response.data);
        return response.data
      })
      .catch(error => {
        Logger.warn('XPCApi:update_participants:error', error);
        return JOIN_ERROR
      });
    return data;
  }

}

export default new XPCApi()