import { AudioInputControl, ControlBar, VideoInputControl, useMeetingManager, PreviewVideo } from 'amazon-chime-sdk-component-library-react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Breakout from '../../stores/Breakout';
//import Logger from '../../stores/Logger'

const WaitingRoomComponent = () => {
  const meeting_manager = useMeetingManager()

  const _start = async () => {
		Breakout.active_room.set_joining(true)
    Breakout.active_room.show_meeting_room()
    meeting_manager.start()
	}
	
	const _leave = async (show_alert = false) => {
    await meeting_manager.leave()
    await Breakout.leave(show_alert)
	}
	
	const WaitingRoomDetails = observer(() => {
    const { active_room } = Breakout
    return (
      <div className="clip__breakout-waiting-room--content">
        {active_room.is_active &&
          <span className="room_is_active">
            Live
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
            </svg>
          </span>
        }
        <h3>{active_room.title}</h3>
        {active_room.desc && <p>{active_room.desc}</p>}
        <span>{active_room.participants_text()}</span>
        <div className="clip__breakout-waiting-room--join">
          {
            (active_room.is_active || Breakout.user?.is_moderator()) && active_room.is_joining ?
              <button className={`clip__breakout-waiting-room--join-button is_joining animate__animated animate__pulse animate__infinite ${active_room.is_about_to_join ? "is_getting_ready" : ""}`}>
                {active_room.is_about_to_join ? "Getting things ready..." : "Joining..."}
              </button>
              : active_room.did_error ?
                <button onClick={active_room.join_room} className="clip__breakout-waiting-room--join-button did_error animate__animated animate__shakeX">
                  An error occured, please try again.
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                  </svg>
                </button>
                : active_room.is_active || Breakout.user?.is_moderator() ?
                  <button onClick={() => _start()} className="clip__breakout-waiting-room--join-button">
                    Join
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                  </button>
              :
              <button disabled={true} className="clip__breakout-waiting-room--join-button is_waiting">
                Waiting for event to start...
              </button>
          }
        </div>
        <button
          className="clip__breakout-waiting-room--cancel"
          onClick={_leave}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
          Cancel & close
        </button>
      </div>
    )
  })

  return (
		<div className="clip__breakout-waiting-room">
      <div className="clip__breakout-waiting-room--video">
        <PreviewVideo nameplate="Me" />
				<ControlBar showLabels className="clip__breakout-waiting-room--controlbar">
					<VideoInputControl appendSampleDevices={false} />
					<AudioInputControl />
				</ControlBar>
				<p>Please allow access to your camera and microphone. You'll join with the camera disabled.</p>
			</div>
			<WaitingRoomDetails />
		</div>
	)
}
export default WaitingRoomComponent