import {
	AudioInputControl,
	ControlBar,
	VideoInputControl,
	VideoTileGrid,
	ControlBarButton,
	ContentShareControl,
	Phone,
	useMeetingManager,
	useRosterState,
	Roster,
	RosterAttendee,
	RosterGroup,
	MicrophoneActivity,
	Attendees
} from 'amazon-chime-sdk-component-library-react'
import React, { useState, useEffect } from 'react';
import Breakout from '../../stores/Breakout';
import { autorun } from 'mobx'
import Logger from '../../stores/Logger'

const ActiveRoomComponent = () => {
	const meeting_manager = useMeetingManager()
	const [ is_directory_shown, set_is_directory_shown ] = useState(true)
	const [ show_audio_controls, set_show_audio_controls ] = useState(true)

	const _init_local_mute = async () => {
    meeting_manager.audioVideo.realtimeMuteLocalAudio()
	}
	
	const _leave = async (show_alert = false) => {
    await meeting_manager.leave()
    await Breakout.leave(show_alert)
  }

	useEffect(() => {
		autorun(() => {
			const { current_user } = Breakout.active_room
			Logger.log('>>>>>>>>> ACTIVE ROOM:current_user', current_user())
			if (current_user()?.is_muted != null && current_user()?.is_muted) {
				_init_local_mute()
			}
			if (current_user()?.is_kicked != null && current_user()?.is_kicked) {
				_leave(true)
			}
			set_show_audio_controls(!Breakout.active_room.is_muted)
			if (Breakout.active_room.is_muted && !Breakout.user.is_moderator()) {
				_init_local_mute()
			}
		})
	})

  const _init_leave = async () => {
    const status = window.confirm("Are you sure you want to leave the meeting?")
    if (status) {
      _leave()
    }
	}

  const hang_up_button_props = {
    icon: <Phone />,
    onClick: () => _init_leave(),
    label: 'End'
	}

	const directory_button_props = {
    icon: <Attendees />,
    onClick: () => set_is_directory_shown(!is_directory_shown),
    label: is_directory_shown ? 'Directory' : 'Directory'
	}

	const AttendeeMenu = (id) => {
		if (!Breakout.user.is_moderator()) {
			return null
		}
		return (
			<>
				<button onClick={() => Breakout.active_room.handle_participant_action("mute", id)}>Mute user</button>
				<button onClick={() => Breakout.active_room.handle_participant_action("kick", id)}>Kick user</button>
			</>
		)
	}
	
	const { roster } = useRosterState();
  const attendees = Object.values(roster);

  const attendeeItems = attendees.map(attendee => {
    const { chimeAttendeeId, name, id } = attendee;
    return (
      <RosterAttendee menu={AttendeeMenu(id)} microphone={<MicrophoneActivity attendeeId={chimeAttendeeId} />} name={name} key={chimeAttendeeId} attendeeId={chimeAttendeeId} />
    );
	});

  return (
		<div className="clip__breakout-active-room">
			<div className="clip__breakout-active-room__video">
				<VideoTileGrid />
				<ControlBar showLabels className="clip__breakout-active-room--controlbar">
					<VideoInputControl appendSampleDevices={false} />
					{(show_audio_controls || Breakout.user.is_moderator()) ? <AudioInputControl appendSampleDevices={false} /> : null}
					{Breakout.user.is_moderator() ? <ContentShareControl /> : null}
					<ControlBarButton {...directory_button_props} />
					<ControlBarButton {...hang_up_button_props} />
				</ControlBar>
			</div>
			{
				is_directory_shown &&
				<Roster>
					<RosterGroup>{attendeeItems}</RosterGroup>
				</Roster>
			}
		</div>
	)
}
export default ActiveRoomComponent