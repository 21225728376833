import { darkTheme, lightTheme, MeetingProvider } from 'amazon-chime-sdk-component-library-react';
import { observer } from 'mobx-react';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import RoomsList from '../components/rooms_list';
import App from '../stores/App';
import Breakout from '../stores/Breakout';
import RoomModule from './Room'

const AppModule = observer(
	class AppModule extends React.Component {

		componentDidMount = async () => {
			App.hydrate(this.props.element)
		}

		_return_section = () => {
			return null
		}

		_return_button = () => {
			if (App.can_show_clip()) {
				return <button
					className={`clip__breakout-button animate__animated ${!Breakout.can_show_button() ? "inactive" : "animate__tada"}`}
					disabled={!Breakout.can_show_button()}
					onClick={Breakout.toggle_main_container}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
						<path strokeLinecap="round" strokeLinejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
					</svg>
					{Breakout.can_show_button() ? "Breakout Rooms" : "..."}
				</button>
			}
			return null
		}

		_return_rooms_list = () => {
			if (App.can_show_clip() && Breakout.can_show_button()) {
				return <RoomsList />
			}
			return null
		}

		_return_active_room = () => {
			if (Breakout.active_room) {
				return <RoomModule />
			}
			return null
		}

		render() {
			return (
				<div className="clip clip__breakout-container">
					{this._return_section()}
					{this._return_button()}
					{this._return_rooms_list()}
					<ThemeProvider theme={App.theme === "light" ? lightTheme : darkTheme}>
						<MeetingProvider>
							{this._return_active_room()}
						</MeetingProvider>
					</ThemeProvider>
				</div>
			)
		}
	}
);
export default AppModule